<template>
  <div>
    <div class="question" @click="toggle()">
      <div class="question-content normalLink">
        {{ $props.question.question }}
        <i v-if="$props.expandable" :class="{'now-ui-icons arrows-1_minimal-down': !expanded, 'now-ui-icons arrows-1_minimal-up': expanded}"></i>
        <!-- <i v-else class="now-ui-icons arrows-1_minimal-right"></i> -->
        <n-button type="primary" v-else style="margin:0; padding:5px 10px;">Chiedi</n-button>
      </div>
    </div>
    <collapse-transition>
      <p v-show="expanded && $props.expandable" class="answer">
        {{ $props.question.answer }}
      </p>
    </collapse-transition>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
import {Button} from "@/components";
import NButton from "@/components/Button";
const Common = require('@/Common.vue').default
export default {
  components: {
    NButton,
    CollapseTransition,
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    expandable: {
      type: Boolean,
      required: true
    },
    fallbackForNotExpandable: {
      type: Function
    }
  },
  data(){
    return{
      expanded: false
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
      Common.addUserStudyLog(this, 'llm-question-click', {
        'question': this.$props.question,
        'new-status': this.expanded,
        'never-asked-before': this.$props.question.clickable
      })
      if(this.expanded && this.question.clickable)
        this.$parent.$props.handler(this.question)
      else if(!this.$props.expandable)
        this.$props.fallbackForNotExpandable(this.question)
    },
  },
};
</script>

<style scoped>
.question-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.question {
  font-weight:500;
  padding: 0.5em;
}

.answer {
  padding: 0.5em;
  margin-bottom: 0 !important;
}
</style>
<template>
    <b-container style="padding-top:5px !important; padding-bottom:5px !important;">
        <b-row align-v="center" style="margin-right:0; margin-left:0;">
            <b-col :style="'padding: 0;' + (onMobile ? 'text-align:center' : 'text-align:left;')" md="1" class="onMobileLineH">
                <BackButton />
            </b-col>
            <b-col md="10" style="text-align:center;" class="onMobileLineH">
                <strong><slot></slot></strong>
            </b-col>
            <b-col md="1" :style="onMobile ? 'text-align:center' : 'text-align:right;'" class="onMobileLineH">
                <FavouritesButton /> 
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import FavouritesButton from './FavouritesButton.vue'
import BackButton from './BackButton.vue'
export default{
    name: 'TitleBar',
    components: { FavouritesButton, BackButton},
    computed: {
        onMobile: function(){
            return window.innerWidth < 768
        },
    },
    methods: {
        back: function(){
            return this.$parent.back()
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 768px){
    .onMobileLineH{
        line-height: 1.2;
        padding-left:0px;
        padding-right:0px;
    }
}
</style>
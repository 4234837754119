<template>
    <span> <!-- DEACTIVATED -->
        <n-button type="primary" style="padding:0.25em" @click="back()" id="backButton" v-if="false && !$props.noBack">{{$t('back')}}</n-button>
    </span>
</template>
<script>
import {Button} from '@/components'
import Swal from 'sweetalert2'

export default {
    props: {
        noBack : {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
        [Button.name] : Button
    },
    methods: {
        back(){
            /*if(this.$parent.hasOwnProperty("back"))
                this.$parent.back();
            else
                this.$router.go(-1);*/
            Swal.fire({
                title: this.$t("percorsiPrec"),
                html: this.generateHtml(),
                confirmButtonText: this.$t("chiudi"),
            })
            this.makeClickable()
        },
        makeClickable(){
            var self = this
            window.setTimeout(() =>{
                for(const el in self.$store.getters.getmaglieHistory){
                    document.getElementById('history_'+el).addEventListener("click", function() {
                        self.$router.push({path:self.$store.getters.getmaglieHistory[el]})
                        if(self.$parent.back)
                            self.$parent.back()
                        Swal.close()
                    })
                }
            },500)
        },
        generateHtml: function(){
            var visited_parsed = this.$store.getters.getmaglieHistory
            var html = ""
            for(const el in visited_parsed){
                html += "<div id='history_"+el+"'><a class='defaultLink'>"+el+"</a></div>"
            }
            return html
        }
    },
}
</script>
<style>
.swal2-confirm{
    color:black !important;
    background-color: #FFAB4C !important;
}
.defaultLink:hover{
    color: #0056b3;
    text-decoration: underline;
    cursor:pointer;
}
.defaultLink{
    color: #0056b3;
}
</style>
<template>
    <div>
        <header v-if="infoMaglia">
            <h3 id="stickyTitle" style=" background-color:#f8f9fa; box-shadow: 0 4px 2px -2px gray;" class="fixed-top">
                <TitleBar>{{s(this.$store.getters.getMagliaSelezionata,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])}}</TitleBar>        
            </h3>
        </header>
        <header v-else>
            <h3 id="stickyTitle" style=" background-color:#f8f9fa; box-shadow: 0 4px 2px -2px gray;" class="fixed-top">
                <TitleBar>
                    <span v-if="!this.isPerson && !this.isObj">{{s(this.$store.getters.getMagliaSelezionata,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])}}</span>
                    <span v-else-if="this.parentResource !== null && this.parentResource['tri:Titolo_'+this.$store.getters.getLanguage] !== null">{{s(this.parentResource,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])}}</span>
                        - 
                    {{s(this.currentElem,["item","tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])}} 
                </TitleBar>
            </h3>
        </header>
        <Header 
            :showMap="true"
            ref="header"
            :margin0="true"
            class="marginTop"
        />
        <!-- NAVIGAZIONE MAGLIA -->
        <b-container fluid style="padding-left:0; padding-right: 0;" v-if="infoMaglia">
            <b-container v-if="this.$store.getters.getMagliaSelezionata === null"><b-skeleton></b-skeleton></b-container>
            <b-container v-else>
                <section>
                    <blockquote class="blck" v-if='s(this.$store.getters.getMagliaSelezionata,["dcterms:description",0,"@value"]) !== ""'>
                        <p class="blck" aria-hidden="true"><span v-html="descr_maglia[0]"></span> <a href="#" v-if="descr_maglia[1]" @click.prevent="expand()">{{$t('scopri')}}</a></p>
                        <a class="sr-only">{{complete_descr_maglia}}</a>
                    </blockquote>
                </section>
                <section>
                    <collapse ref="collapseMaglia">
                        <collapse-item :title='"**"+s(this.$store.getters.getMagliaSelezionata,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])+"**" + $t("luoghiApp")'>
                            <CardList
                                list-title="I luoghi del percorso"
                                :elems="Object.values(this.objFilter(this.maglia,el => !el.item.hasOwnProperty('tri:Non_vertice')))" 
                                :handler="this.changeItem"
                                :thumbnail="this.thumbnailSrc"
                                :alt="this.alt"
                                :title="this.cardTitle"
                                :body="this.cardBody"
                            />
                        </collapse-item>
                    </collapse>
                </section>
            </b-container>
        </b-container>
        <!-- NAVIGAZIONE ITEM -->
        <div v-else>
            <b-container fluid v-if="!this.pivotReady"><b-skeleton></b-skeleton></b-container>
            <b-container v-else>
                <section>
                    <h3>
                      <b-container>
                        <n-button type="primary" style="padding-top:0.25em; padding-bottom:0.25em" @click="seeDetails($store.getters.getMagliaSelezionata)">{{$t('backToMaglia') +s(this.$store.getters.getMagliaSelezionata,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])}}</n-button>
                        <span v-if="this.isPlace && this.$store.getters.getMagliaMedia !== null && this.$store.getters.getMagliaMedia.length > 0">
                          <n-button type="primary" style="padding-top:0.25em; padding-bottom:0.25em" v-if="this.$refs.header.showM" @click="toggleMapVisibility">{{$t('mostraImg')}}</n-button>
                          <n-button type="primary" style="padding-top:0.25em; padding-bottom:0.25em" v-else @click="toggleMapVisibility">{{$t('mostraMappa')}}</n-button>
                        </span>
                      </b-container>
                    </h3>
                    <blockquote class="blck" v-if='descr !== ""'>
                        <p class="blck" aria-hidden="true"><span v-html="descr[0]"></span> <a href="#" v-if="descr[1]" @click.prevent="expand()">{{$t("scopri")}}</a></p>
                        <a class="sr-only">{{complete_descr}}</a>
                    </blockquote>
                    <blockquote class="blockquote blockquote-primary mb-0" style="text-align:center" v-if='s(this.currentElem,["item","tri:Data",0,"@value"])'>
                        <p>{{s(this.currentElem,["item","tri:Data",0,"@value"])}}</p>
                    </blockquote><br>
                </section>
            </b-container>
            <div>
                <section v-if="!baseline">
                  <b-container>
                    <header><h3 class="titlePercorso">{{$t("llm_questions")}}</h3></header>
                  <span v-if="this.loading_llm_questions">
                    <b-skeleton v-for="index in 10" :key="index"></b-skeleton>
                  </span>
                  <questions-box :questions="this.llm_questions" :handler="get_answer" v-else-if="generated && $store.getters.getNumQueries < 30" :expandable="!free" :fallback-for-not-expandable="scrollAndHighlight"/>
                  <collapse v-if="!this.loading_llm_questions && this.free">
                    <collapse-item ref="chatbox-collapse" id="chatbox" :title="$t('openEndedQuestion')" :initiallyActive="true">
                      <p v-for="msg in chat_history" :key="msg" v-html="msg"></p>
                      <p v-if="guida_busy"><i>{{$t('guida_busy')}}</i></p>
                      <p v-else-if="enqueued.length > 0"><i>{{$t('enqueued')}}</i></p>
                      <p v-else-if="loading_llm_answer"><i>{{$t('sta_scrivendo')}}</i></p>
                      <span v-if="$store.getters.getNumQueries < 30"><b-form-input id="customQuestion" v-model="question_input" type="text" v-on:keyup.enter="get_custom_answer()" placeholder="Please write here your question to delve into this topic..." :disabled="loading_llm_answer || guida_busy"/><h3 style="text-align: left; margin-top:0;"><n-button id="customQuestionButton" type="primary" @click="get_custom_answer()" :disabled="question_input.length === 0">{{ $t('ask') }}</n-button></h3></span>
                      <p v-else><i>Thanks for interacting with the system! You may now finish to read the answer, if you want, and then go to the next phase.</i></p>
                    </collapse-item>
                  </collapse>
                  </b-container>
                </section>
                <div>
                    <section>
                        <b-container style="padding-top:10px">
                            <collapse ref="collapsePivot">
                                <collapse-item :title='$t("altro") + "**" +s(this.$store.getters.getMagliaSelezionata,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])+"**"'>
                                    <div style="padding-top:1%">
                                        <CardList
                                            list-title="altri luoghi del percorso"
                                            :elems="this.nextItems.concat(Object.values(this.otherLocations))" 
                                            :handler="this.changeItem"
                                            :thumbnail="this.thumbnailSrc"
                                            :alt="this.alt"
                                            :title="this.cardTitle"
                                            :body="this.cardBody"
                                        />
                                    </div>
                                </collapse-item>
                            </collapse>
                        </b-container>
                    </section>
                </div>
            </div>
        </div>
        <starter-footer :backgroundColor="'black'"></starter-footer>
    </div>
</template>
<script>
    import Vue from 'vue';
    import StarterFooter from '../layout/StarterFooter.vue';
    import {Button} from '@/components'
    import Header from './components/Header.vue'
    import CardList from './components/CardList.vue'
    import TitleBar from './components/TitleBar.vue'
    import {Collapse, CollapseItem} from '@/components'
    import {
        hyphenateSync
    } from "hyphen/it";
    import Swal from 'sweetalert2'
    import QuestionsBox from "@/pages/components/QuestionsBox";
    const Common = require('@/Common.vue').default
    export default{
        name: 'percorso',
        components: { StarterFooter, [Button.name]: Button, Header, CardList, Collapse, CollapseItem, TitleBar, QuestionsBox},
        data(){
            return {
                maglia: new Object(), //Key = Id item; Value: Object with keys item, pivot, coordinate, and either mediaList or mediaList_pivot
                personeMaglia: new Object(), //Id Item => Object with keys item, pivot, and either mediaList or mediaList_pivot
                oggettiMaglia: new Object(), //Id Item => Object with keys item, pivot, and either mediaList or mediaList_pivot
                numberOfElems: -1, // Number of items in the current maglia
                pivotReady: false, // True iff pivot was completely loaded
                nextItems: [], // Places in the same maglia
                inGeodato : null, // List of items in the current place (if we are exploring one)
                correlato: null, // List of items related to the current one
                expandedDescription: false, // True iff the descripition should be displayed completely
                infoMaglia: false, // True iff we are exploring the maglia (and not a single item)
                parentResource: null, // If we are exploring an object / a person, parentResource is the corresponding place.
                updatingCorr: 0, // Used while updating the list of related items (correlato)
                updatingIn: 0, // Used while updating the list of items in a given place (inGeodato),
                imgUrl : "", // Url of the current main image (used for storing favourites)
                orangeIcon: new L.Icon({
                    iconUrl: 'https://exptriangolazioni.ontomap.eu/out2.png',
                    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                    iconSize: [27,34],
                    iconAnchor: [13,34.5],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                }),
                blackIcon: new L.Icon({
                    iconUrl: 'https://exptriangolazioni.ontomap.eu/output-onlinepngtools_others.png',
                    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                    iconSize: [27,34],
                    iconAnchor: [13,34.5],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                }),
                sottostorie: [], // Objects representing substories for the current maglia
                llm_questions: [],
                loading_llm_questions: false,
                loading_llm_answer: false,
                enqueued: [],
                relaunched_ask: false,
                custom_el: {
                  "question": "",
                  "answer": "",
                  "clickable":true
                },
                chat_history: [],
                question_input: '',
                guida_busy: false,
                abort_answers: false,
                abort_questions: false
            };
        },
        props: {
            percorso : {
                type: Number, 
                required: true
            },
        },
        methods: {
            scrollAndHighlight: function(question){
              if(!this.$refs["chatbox-collapse"].active){
                this.$refs["chatbox-collapse"].activate()
                window.setTimeout(() => this.scrollAndHighlight_internal(question), 500)
              }
              else this.scrollAndHighlight_internal(question)
            },
            scrollAndHighlight_internal: function(question){
              let container = document.getElementById('content-chatbox')
              let paragraphs = container.getElementsByTagName('p')
              for(let i = 0; i < paragraphs.length; i++){
                let paragraph = paragraphs[i];
                let text = paragraph.textContent || paragraph.innerText;
                if (text.search(question.question) !== -1) {
                  const yOffset = -200;
                  const y = paragraph.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'smooth'});
                  // paragraph.scrollIntoView({behavior:'smooth'});
                  setTimeout(function() {
                    paragraph.classList.add('flash');
                  }, 1000);
                  setTimeout(function() {
                    paragraph.classList.remove('flash');
                  }, 5000);
                  break;
                }
              }
            },
            scrollToChat: function(){
              if(!this.$refs["chatbox-collapse"].active){
                this.$refs["chatbox-collapse"].activate()
                window.setTimeout(() => this.scrollToChat_internal(),500)
              }
              else this.scrollToChat_internal()
            },
            scrollToChat_internal: function(){
              // document.getElementById('customQuestion').scrollIntoView({behavior:'smooth'})
              const yOffset = -200;
              const y = document.getElementById('customQuestion').getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({top: y, behavior: 'smooth'});
              document.getElementById('customQuestion').focus()
            },
            get_custom_answer: function(){
              if(this.question_input.length > 0){
                this.chat_history.push("<b>Tu: </b>" + this.question_input)
                this.custom_el.clickable = true
                this.custom_el.question = this.question_input
                this.custom_el.answer = ''
                this.question_input = ''
                Common.addUserStudyLog(this,'custom_llm_question', this.custom_el)
                window.setTimeout(() => this.get_answer(this.custom_el), 1)
              }
            },
            format_answer: function(text){
              const urlRegex = /(https?:\/\/[^\s]+)/g; // regex for both http:// and https://
              return text.replaceAll("\n","<br>").replace(urlRegex, function(url) {
                return '<a href="' + url + '" target="_blank">' + url + '</a>'; // return the replaced text
              })
            },
            inspect_queue: function(timeout){
              const self = this
              window.setTimeout(
                  () => {
                    if(self.enqueued.length > 0){
                      let first = self.enqueued.shift()
                      //console.log(first)
                      self.get_answer(first, true)
                    }
                  }, timeout)
            },
            get_answer: function(el, force=false){
              if((force || (el.clickable && !this.guida_busy)) && !this.loading_llm_answer){
                if(el.question !== this.custom_el.question && !force){
                  this.chat_history.push("<b>Question: </b>" + el.question)

                }
                if(this.free)
                  this.scrollToChat()

                const self = this
                self.loading_llm_answer = true
                el.answer = "Loading..."
                Common.askLlm(this, "Please briefly answer this question. Your answer should be original and engaging, and not repeat anything you said before. Most importantly, your answer should be factual and related to the place we are talking about. " + el.question, response => {
                  self.loading_llm_answer = false
                  el.answer=response
                  if(self.abort_answers){
                    self.abort_answers = false
                    return
                  }
                  el.clickable = false
                  let character = el.question === this.custom_el.question ? 'Guide' : 'Answer'
                  let ans = el.question === this.custom_el.question ? this.custom_el : el
                  this.chat_history.push("<b>"+character+":</b> " + this.format_answer(ans.answer.replaceAll("<", "&lt;").replaceAll(">", "&gt;")))
                  // document.getElementById('customQuestion').scrollIntoView({behavior:'smooth'})
                  if(self.enqueued.length === 0)
                    this.guida_busy = false
                  window.setTimeout(() => document.getElementById('customQuestion').focus(), 2)
                  Common.addUserStudyLog(self,'llm-answer', {
                    'answer': el.answer
                  })
                  self.inspect_queue(500)
                }, 'llama-2-70b-chat', true, () => {
                  self.guida_busy = true
                }, (response) => {
                  // Error callback
                  el.clickable = false
                  self.loading_llm_answer = false
                  el.answer='Still loading... Please wait'
                  if(self.abort_answers){
                    self.abort_answers = false
                    return
                  }
                  self.enqueued = [el, ...self.enqueued]
                  this.guida_busy = true
                  self.inspect_queue(10000)
                })
              }
              else if(el.clickable){
                el.answer = "Loading..."
                this.enqueued.push(el)
                if(el.question !== this.custom_el.question && !force){
                  this.chat_history.push("<b>Question: </b>" + el.question)
                }
                if(this.free)
                  this.scrollToChat()
              }
            },
            // Converts youtube url into the right one to be embedded.
            videoSource: function(media_element){
                if(!media_element)
                  return ""
                if(media_element['o:source'].includes("youtu.be/"))
                    return "https://youtube.com/embed/"+media_element['o:source'].split("/").pop()+"?modestbranding=1&playsinline=1&rel=0"
                else
                    return "https://youtube.com/embed/"+media_element['o:source'].split("/watch?v=").pop()+"?modestbranding=1&playsinline=1&rel=0"
            },

            // Opens the video of a cluster in a popup
            openVideo: function(elem = null){
              let description;
              if(elem === null){
                var title = this.$store.getters.getVideoCluster["o:title"] ? this.$store.getters.getVideoCluster["o:title"] : 'Video'
                var url = this.$store.getters.getVideoCluster
                description = '';
              }
              else{
                var title = this.s(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])
                if(this.s(elem,["dcterms:description",0,"@value"]) === '')
                  {
                    description = '';
                  }
                else {
                  description = Common.processMarkdown(this.s(elem, ["dcterms:description", 0, "@value"])) + '<br><br>';
                  if(this.s(elem, ["dcterms:description", 0, "@value"]).startsWith("--left--")){
                    description = "<div style='text-align:left !important; margin: 10px;'>"+ description.replace("--left--","") + "</div>"
                  }
                  else
                    description = "<div style='margin: 10px;'>"+ description.replace("--left--","") + "</div>"
                }
                var url = Common.getMainVideo(elem["mediaList"])
              }
              var self = this
              if(url)
                Swal.fire({
                  title: title,
                  html: description+'<div class="embed-responsive embed-responsive-16by9">\
                                <iframe class="embed-responsive-item" src="'+this.videoSource(url)+'" allowfullscreen></iframe>\
                            </div>',
                  confirmButtonText: self.$t("chiudi"),
                  confirmButtonColor: '#979797',
                })
              else
                Swal.fire({
                  title: title,
                  html: description,
                  confirmButtonText: self.$t("chiudi"),
                  confirmButtonColor: '#979797',
                })
              document.getElementById("swal2-html-container").style.marginLeft = "0px"
              document.getElementById("swal2-html-container").style.marginRight = "0px"
              document.getElementById("swal2-html-container").style.lineHeight = "1.61em"
              document.getElementById("swal2-title").style.fontSize = "1.1em"
            },

            // Opens the description of a cluster in a popup
            openDescription: function(){
                var self = this
                var txt = this.$store.getters.getClusterDescription["dcterms:description"][0]["@value"]
                if(!txt){
                    txt = self.$t("noData")
                }
                Swal.fire({
                    //title: self.$t("maglieCorrelate") + self.s(self.$store.getters.getMagliaSelezionata,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),
                    html: Common.processMarkdown(txt),
                    confirmButtonText: self.$t("chiudi"),
                    confirmButtonColor: '#979797',
                })
                document.getElementById("swal2-html-container").style.marginLeft = "0px"
                document.getElementById("swal2-html-container").style.marginRight = "0px"
            },

            // Called when we click on "Discover more"
            expand: function(){
                Common.addUserStudyLog(this, 'Expand-Percorso', {
                  'pivotId' : this.$props.percorso
                })
                this.expandedDescription = true
            },

            // Like the "filter" method for lists, but this works for objects.
            objFilter(obj, predicate){
                return Object.keys(obj)
                    .filter( key => predicate(obj[key]) )
                    .reduce( (res, key) => (res[key] = obj[key], res), {} );
            },

            // Wrapper for Common.safeString
            s(unsafeString,chain,debugging_label = null){
                return Common.safeString(unsafeString,chain,debugging_label)
            },

            //Called when changing item
            changeItem(el){
                this.$refs.header.showM = false // Show the image for the next element
                var newMarker = el.item['o:id']
                this.nextItems = []
                var self = this

                // Update the markers on the map so that the newly selected item becomes the selected one in the map
                if(this.isPlace  && this.s(this.maglia,[this.$props.percorso,"marker"]) && this.s(this.maglia,[this.$props.percorso,"item","tri:Non_vertice",0,"@value"]) !== "T"){
                    this.map.operationOnMarker(function(){
                        self.maglia[self.$props.percorso]["marker"].setIcon(self.blackIcon)
                        self.maglia[self.$props.percorso]["marker"].setZIndexOffset(0)
                        self.maglia[self.$props.percorso]["marker"]._icon.classList.remove("huechange");
                        self.maglia[self.$props.percorso]["marker"]._icon.classList.add("huestd");
                    })
                }
                if(this.isPlace && this.s(this.maglia,[this.$props.percorso,"marker"]) && this.s(this.maglia,[this.$props.percorso,"item","tri:Non_vertice",0,"@value"]) === "T"){
                    this.map.map.removeLayer(this.s(this.maglia,[this.$props.percorso,"marker"]))
                }
                if(this.maglia.hasOwnProperty(newMarker)){ // If new item is a location
                    this.selectPivot(this.maglia[newMarker]["pivot"])
                    if(this.s(this.maglia,[newMarker,"marker"])){ // If it actually has location data
                        if(this.s(this.maglia,[newMarker,"item","tri:Non_vertice",0,"@value"]) !== "T"){ // If is being displayed in the map
                            this.map.operationOnMarker(function(){
                                self.maglia[newMarker]["marker"].setIcon(self.orangeIcon)
                                self.maglia[newMarker]["marker"].setZIndexOffset(1000)
                                self.maglia[newMarker]["marker"]._icon.classList.add("huechange");
                                self.maglia[newMarker]["marker"]._icon.classList.remove("huestd");
                                self.map.pan(self.maglia[newMarker]["marker"].getLatLng())
                            })
                        }
                        else{
                            // Item was not previously displayed (eg, non_vertice is T)
                            this.map.addToMap(this.s(this.maglia,[newMarker,"coordinate","o-module-mapping:lat"]),this.s(this.maglia,[newMarker,"coordinate","o-module-mapping:lng"]),this.s(this.maglia,[newMarker,'item',"tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"]),this.maglia[newMarker])
                            this.map.operationOnMarker(function(){
                                self.maglia[newMarker]["marker"].setIcon(self.orangeIcon)
                                self.maglia[newMarker]["marker"].setZIndexOffset(1000)
                                self.maglia[newMarker]["marker"]._icon.classList.add("huechange");
                                self.maglia[newMarker]["marker"]._icon.classList.remove("huestd");
                                self.map.pan(self.maglia[newMarker]["marker"].getLatLng())
                            })
                        }
                        
                        // Draw lines
                        for(var elem in this.maglia){
                            if(this.maglia[elem].item.hasOwnProperty("tri:linea_continua")){
                                this.maglia[elem].item["tri:linea_continua"].forEach(
                                    el => {
                                        if(el["value_resource_id"] === newMarker) this.nextItems.push(this.maglia[elem])
                                        else if(elem === newMarker) this.nextItems.push(this.maglia[el["value_resource_id"]])
                                    }
                                );
                            }
                            if(this.maglia[elem].item.hasOwnProperty("tri:linea_tratteggiata")){
                                this.maglia[elem].item["tri:linea_tratteggiata"].forEach(
                                    el => {
                                        if(elem === newMarker && this.maglia[el["value_resource_id"]]) this.nextItems.push(this.maglia[el["value_resource_id"]])
                                        else if(el["value_resource_id"] === newMarker) this.nextItems.push(this.maglia[elem])

                                    }
                                );
                            }
                        }
                    }
                    else if(this.s(this.maglia,[newMarker,"coordinate"])){
                        // Item was not previously displayed (eg, non_vertice is T)
                        this.selectPivot(this.maglia[newMarker]["pivot"])
                        this.map.addToMap(this.s(this.maglia,[newMarker,"coordinate","o-module-mapping:lat"]),this.s(this.maglia,[newMarker,"coordinate","o-module-mapping:lng"]),this.s(this.maglia,[newMarker,'item',"tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"]),this.maglia[newMarker])
                        this.map.operationOnMarker(function(){
                            self.maglia[newMarker]["marker"].setIcon(self.orangeIcon)
                            self.maglia[newMarker]["marker"].setZIndexOffset(1000)
                            self.maglia[newMarker]["marker"]._icon.classList.add("huechange");
                            self.maglia[newMarker]["marker"]._icon.classList.remove("huestd");
                            self.map.pan(self.maglia[newMarker]["marker"].getLatLng())
                        })
                    }
                }
                else if(this.oggettiMaglia.hasOwnProperty(newMarker)){
                    this.selectPivot(this.oggettiMaglia[newMarker]["pivot"])
                }
                else{
                    this.selectPivot(this.personeMaglia[newMarker]["pivot"])
                }

                // Reset navigation status
                this.infoMaglia = false
                this.expandedDescription = false

                this.$router.push({name: 'percorso', params: {percorso: el.item['o:id']}})

                // Adjust margins so that the image is not covered by the title
                window.setTimeout(() => {
                    var all = document.getElementsByClassName('marginTop');
                    var offsetHeight = document.getElementById('mainNav').offsetHeight + document.getElementById('stickyTitle').offsetHeight;
                    for (var i = 0; i < all.length; i++) {
                        all[i].style.marginTop = (offsetHeight) + "px";
                    }
                    var newC = this.$store.getters.getmaglieHistory
                    newC[this.$store.getters.getMagliaSelezionata["tri:Titolo_" + this.$store.getters.getLanguage][0]["@value"]] = [location.pathname, this.favTitle]
                    this.$store.commit('maglieHistory',newC)
                },1000)
            },

            // Get the url for the thumbnail of elem
            thumbnailSrc: function(elem){
                var images_element = this.s(elem,["mediaList"])
                if(images_element){
                    return this.s(Common.findThumbnail(images_element),["thumbnail_display_urls","square"]);
                }
                else{
                    images_element = this.s(elem,["mediaList_pivot"])
                    if(images_element)
                        return this.s(Common.findThumbnail(images_element),["thumbnail_display_urls","square"])
                    else return ""
                }
            },

            // Methods required by CardList components
            alt: function(elem){return this.s(elem['item'],["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
            cardTitle: function(elem){return this.s(elem['item'],["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
            cardBody: function(elem){
                if(this.s(elem['item'],["dcterms:description",0,"@value"]) !== "")
                    return this.s(elem['item'],["dcterms:description",0,"@value"]).substr(0,200) + (this.s(elem['item'],["dcterms:description",0,"@value"]).length > 200 ? '...' : '')
                else
                    return this.s(elem['pivot'],["dcterms:description",0,"@value"]).substr(0,200) + (this.s(elem['pivot'],["dcterms:description",0,"@value"]).length > 200 ? '...' : '')
            },

            altSottostorie: function(elem){return this.s(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
            cardTitleSottostorie: function(elem){return this.s(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
            cardBodySottostorie: function(elem){
              if(this.s(elem,["dcterms:description",0,"@value"]) !== "")
                return this.s(elem,["dcterms:description",0,"@value"]).substr(0,200) + (this.s(elem,["dcterms:description",0,"@value"]).length > 200 ? '...' : '')
              else
                return this.s(elem,["dcterms:description",0,"@value"]).substr(0,200) + (this.s(elem,["dcterms:description",0,"@value"]).length > 200 ? '...' : '')
            },

            thumbnailSrcMaglie: function(elem){
                return this.s(Common.findThumbnail(elem["mediaList"]),["thumbnail_display_urls","square"]);
            },
            altMaglie: function(elem){return this.s(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
            cardTitleMaglie: function(elem){return this.s(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
            cardBodyMaglie: function(elem){
                return this.s(elem,["tri:Descrizione_"+this.$store.getters.getLanguage,0,"@value"]).substr(0,200) + (this.s(elem,["tri:Descrizione_" + this.$store.getters.getLanguage,0,"@value"]).length > 200 ? '...' : '')
            },

            initSubstories : function(id){
              var self = this
              Common.getElemByPropertyId(this,258,id, function(res2){
                self.sottostorie = []
                res2.body.forEach(el => {
                  Common.getElementMedia(self,el, function(media){
                    el["mediaList"] = media
                    self.sottostorie.push(el)
                  })
                })
              },129)
            },
            // Called when a new maglia "m" is selected
            seeDetails : function(m){
              var self = this
              this.$store.dispatch('initMagliaSelezionata',[self,m["@id"],undefined])
              Common.getElemByPropertyId(this,258,m['o:id'], function(res){
                //location.href = '/maglia/'+res.body[0]['o:id']
                self.$router.push({path : '/maglia/'+res.body[0]['o:id']})
                self.getNewData()
                self.initSubstories(res.body[0]['o:id'])
              },121)
            },

            // Initialize media elements for obj.
            // Obj is an object in maglia, personeMaglia or oggettiMaglia
            // When done, obj will have an additional attribute "mediaList" which 
            // Will be a list of media elements.
            initMedia: function(obj){
                var self = this
                Common.getElementMedia(this,obj.item,(localMediaList) => {
                    var mediaList = localMediaList
                    Common.getElementMedia(self,obj.pivot,(mediaListPivot) => {
                        mediaList = mediaList.concat(mediaListPivot)
                        Vue.set(obj,"mediaList",mediaList)
                        self.updateImg()
                    })
                })
            },

            // Initialize pivot "elem"
            selectPivot: function(elem){
                this.pivotReady = false
                var self = this;
                Common.getElementImages(this,elem,mediaList => {
                    self.$store.commit('setPivotImages',mediaList)
                    self.$store.dispatch('setPivotSelezionato',[self,elem])
                    self.pivotReady = true
                })
            },

            // To be called when changing maglia
            getNewData: function(){
                var self = this
                this.map.reset();
                this.$refs.header.showM = true
                window.setTimeout(function(){self.getNew()},200)
            },

            // Called by getNewData and mounted, initializes everything
            getNew: function(){
                // Initialize variables
                this.maglia = new Object()
                this.personeMaglia = new Object()
                this.oggettiMaglia = new Object()
                this.numberOfElems = -1
                this.pivotReady = false
                this.expandedDescription = false
                
                var self = this;
                // Get info about the current element
                Common.getElemById(this,this.percorso, (response) => {
                    
                    // Get the maglia, if necessary
                    this.$store.dispatch('initMagliaSelezionata',[self,response.body["tri:appartiene_a_maglia"][0]["@id"]])

                    // Get substories
                    this.initSubstories(response.body["tri:appartiene_a_maglia"][0]["value_resource_id"])

                    // Get the corresponding pivot
                    Common.getElemByUrl(self,response.body["tri:coincide_con_pivot"][0]["@id"],
                    (r) => {
                        this.selectPivot(r.body)
                        window.setTimeout(() => {
                            var all = document.getElementsByClassName('marginTop');
                            var offsetHeight = document.getElementById('mainNav').offsetHeight + document.getElementById('stickyTitle').offsetHeight;
                            for (var i = 0; i < all.length; i++) {
                                all[i].style.marginTop = (offsetHeight) + "px";
                            }
                        },1000)

                        // If the item is a person or an object, there is no need to initialize it on the map
                        if(response.body["@type"][1] !== "tri:geodato"){
                            var obMaglia = response.body["@type"][1] === "tri:persona" ? self.personeMaglia : self.oggettiMaglia
                            self.map.initMap()
                            self.setMapVisibility()
                            Vue.set(obMaglia,response.body["o:id"],{'item': response.body, 'pivot': r.body});    
                            self.initMedia(obMaglia[response.body["o:id"]])
                        }
                        // ... otherwise, we need to initialize the map
                        else{
                            if(r.body.hasOwnProperty("o-module-mapping:marker") /*&& this.s(response.body,["tri:Non_vertice",0,"@value"]) !== "T"*/){
                                // Get module-mapping
                                Common.getElemByUrl(self,r.body["o-module-mapping:marker"][0]["@id"], (r2) =>
                                {
                                    Vue.set(self.maglia,response.body["o:id"],{'item': response.body, 'pivot': r.body, 'coordinate': r2.body});
                                    this.map.initMapAndSetCurrent(r2.body["o-module-mapping:lat"],r2.body["o-module-mapping:lng"],self.s(r.body,["tri:Titolo_"+self.$store.getters.getLanguage,0,"@value"]),self.maglia[response.body["o:id"]])
                                    self.setMapVisibility()
                                    //self.maglia[response.body["o:id"]].marker.on('click',function(){self.changeItem(self.maglia[response.body["o:id"]])})
                                    if(window.location.href.includes("maglia")){
                                        self.map.operationOnMarker(function(){
                                            if(self.maglia[response.body["o:id"]].marker){
                                                self.maglia[response.body["o:id"]].marker.setIcon(self.blackIcon)
                                                self.maglia[response.body["o:id"]].marker.setZIndexOffset(0)
                                                self.maglia[response.body["o:id"]].marker._icon.classList.remove("huechange")
                                                self.maglia[response.body["o:id"]].marker._icon.classList.add("huestd")
                                            }
                                        })
                                    }
                                    self.initMedia(self.maglia[response.body["o:id"]])
                                });
                            }
                            else{
                                Vue.set(self.maglia,response.body["o:id"],{'item': response.body, 'pivot': r.body, 'coordinate': null});
                                self.map.initMap();
                                self.setMapVisibility()
                                self.initMedia(self.maglia[response.body["o:id"]])
                            }
                        }
                    })
                    
                    // Get all items in the corresponding maglia
                    Common.getElemByPropertyId(self,258,response.body["tri:appartiene_a_maglia"][0]["value_resource_id"],(r) => {
                        var items = Array.isArray(r.body) ? r.body : [r.body];
                        self.numberOfElems = 0;
                        items.forEach(element => {
                            if(element["@type"][1] === "tri:geodato"){
                                self.numberOfElems++;
                            }
                        })
                        items.forEach(element => {
                            if(element["o:id"] !== self.$props.percorso){
                                // Get the corresponding pivot for each item in the maglia
                                if(element["tri:coincide_con_pivot"]){
                                    Common.getElemByUrl(self,element["tri:coincide_con_pivot"][0]["@id"],
                                    (r) => {
                                        if(element["@type"][1] === "tri:persona"){
                                            Vue.set(self.personeMaglia,element["o:id"],{'item': element,'pivot':r.body})
                                            self.initMedia(self.personeMaglia[element["o:id"]])
                                        }
                                        else if(element["@type"][1] === "tri:oggetto"){
                                            Vue.set(self.oggettiMaglia,element["o:id"],{'item': element,'pivot':r.body})
                                            self.initMedia(self.oggettiMaglia[element["o:id"]])
                                        }
                                        else{
                                            // Get coordinates
                                            if(r.body.hasOwnProperty("o-module-mapping:marker")) {
                                              Common.getElemByUrl(self, self.s(r.body, ["o-module-mapping:marker", 0, "@id"]), (r2) => {
                                                Vue.set(self.maglia, element["o:id"], {
                                                  'item': element,
                                                  'pivot': r.body,
                                                  'coordinate': r2.body
                                                });
                                                self.initMedia(self.maglia[element["o:id"]])
                                                if (this.s(element, ["tri:Non_vertice", 0, "@value"]) !== "T") {
                                                  self.map.addToMap(r2.body["o-module-mapping:lat"], r2.body["o-module-mapping:lng"], self.s(r.body, ["tri:Titolo_" + self.$store.getters.getLanguage, 0, "@value"]), self.maglia[element["o:id"]])
                                                  //self.maglia[element["o:id"]].marker.on('click',function(){self.changeItem(self.maglia[element["o:id"]])})
                                                  this.panMap(r2)
                                                }
                                              });
                                            }
                                            else{
                                              Vue.set(self.maglia, element["o:id"], {
                                                'item': element,
                                                'pivot': r.body,
                                              });
                                              self.initMedia(self.maglia[element["o:id"]])
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    });
                });
            },

            // Focuses the map on marker r2
            panMap: function(r2){
                var self = this
                if(!self.map.map)
                    return window.setTimeout(() => self.panMap(r2),150)
                if(self.map.map.getCenter().lat === 0 && self.map.map.getCenter().lng === 0){
                    self.map.pan([r2.body["o-module-mapping:lat"],r2.body["o-module-mapping:lng"]]);
                }
            },

            // Toggles map visibility according to the resource we are exploring (maglia or item)
            setMapVisibility: function(){
                if(this.infoMaglia){
                    this.$refs.header.showM = true
                }
                else{
                    this.$refs.header.showM = false
                }
            },

            // Toggles map visibility according to its current state
            toggleMapVisibility: function(){
                this.$refs.header.showM = ! this.$refs.header.showM
            },

            // Updates image carousel in the header
            updateImg: function(){
                var images_element = this.s(this.currentElem,["mediaList"])
                if(images_element){
                    this.imgUrl = this.s(Common.findMainImage(images_element),["thumbnail_display_urls","large"]);
                }
                else{
                    images_element = this.s(this.currentElem,["mediaList_pivot"])
                    if(images_element)
                        this.imgUrl =  this.s(Common.findMainImage(images_element),["thumbnail_display_urls","large"])
                    else this.imgUrl =  ""
                }
                this.$refs.header.updateImg(images_element)
            },

            // Function required by BackButton component
            back: function(){
                this.getNewData()               
            },

            // Extracts title and source from el
            elValue: function(el){
                return Common.safeString(el,["o:title"]) + " " + Common.safeString(el,["o:source"])
            },

            // Gets the items in place n
            updateIn: function(n){
                var self = this
                while(this.updatingIn > 0) return window.setTimeout(function(){self.updateIn(n)},1000)
                Common.getElemByPropertyId(self,261,n.item["o:id"],(resp) => {
                    if(resp.body.length === 0) self.inGeodato = null
                    else{
                        self.inGeodato = []
                        self.updatingIn += resp.body.length
                        resp.body.forEach(el => {
                            Common.getElemByUrl(self,self.s(el,["tri:coincide_con_pivot",0,"@id"]),(resp2) => {
                                var newEl = {'item':el,'pivot':resp2.body}
                                self.inGeodato.push(newEl)
                                self.initMedia(newEl)
                                self.updatingIn--
                            })
                        })
                    }
                });
            },

            updateQuestions: function(){
              if(!this.generated) return
              if(this.abort_questions){
                this.abort_questions = false
                return
              }
              if(!this.infoMaglia){
                // console.log("LAUNCHING")
                const self = this
                this.loading_llm_questions = true
                this.relaunched_ask = true
                Common.getQuestionsFromLlm(this, questions => {
                  Common.addUserStudyLog(self,'llm-questions', {
                    'questions': questions
                  })
                  self.llm_questions = questions
                  self.loading_llm_questions = false
                }, 3, res => {
                  // console.log("Asking again shortly")
                  this.relaunched_ask = false
                  window.setTimeout(() => {
                    if(!self.relaunched_ask) {
                      self.updateQuestions()
                    }
                  }, 10000)
                })
              }
            },

            // Gets related items to item n
            updateCorr: function(n){
                var self = this
                while(this.updatingCorr > 0) return window.setTimeout(function(){self.updateCorr(n)},1000)
                Common.getElemByPropertyId(self,260,n.item["o:id"],(resp) => {
                    if(resp.body.length === 0) self.correlato = null
                    else{
                        self.correlato = []
                        self.updatingCorr += resp.body.length
                        resp.body.forEach(el => {
                            Common.getElemByUrl(self,self.s(el,["tri:coincide_con_pivot",0,"@id"]),(resp2) => {
                                var newEl = {'item':el,'pivot':resp2.body};
                                self.correlato.push(newEl)
                                self.initMedia(newEl)
                                self.updatingCorr--
                            })
                        })
                    }
                });
            }
        },
        // Tri-LOG
        beforeRouteUpdate(to, from, next) {
            this.$nextTick(() => {
              this.$store.commit('pruneLlmMessages', from.path)
              Common.addLog(this,to.path, res => {});
              this.llm_questions= []
              this.loading_llm_questions= false
              this.loading_llm_answer= false
              this.enqueued= []
              this.relaunched_ask= false
              this.custom_el = {
                "question": "",
                "answer": "",
                "clickable":true
              }
              this.chat_history = []
              this.question_input = ''
              this.guida_busy = false
              if(this.loading_llm_answer)
                this.abort_answers = true // Se una risposta stava arrivando, ignorala
              if(this.loading_llm_questions)
                this.abort_questions = true
              next();
            });
        },
        mounted : function(){        
            // Tri-LOG
            Common.addLog(this,this.$route.path, res => {});

            var self = this
            window.addEventListener('popstate', function(event) {
                self.getNewData()
            },false);
            this.infoMaglia = window.location.href.includes('maglia')
            var offsetHeight = document.getElementById('mainNav').offsetHeight;
            document.getElementById('stickyTitle').style.marginTop = (offsetHeight - 0.5 ) + "px"
            this.getNew();    
        },

        watch: {
            maglia(n,o){
                if(this.numberOfElems === Object.keys(n).length){
                    this.map.applyChanges()
                    // We loaded every element in the maglia. Now let's link them in the map.
                    var self = this
                    for(var elem in n){
                        if(!n[elem].item.hasOwnProperty("tri:Non_vertice")){       
                            if(n[elem].item.hasOwnProperty("tri:linea_continua")){
                                n[elem].item["tri:linea_continua"].forEach(
                                    el => {
                                        if(!n[el["value_resource_id"]].item.hasOwnProperty("tri:Non_vertice")){
                                            this.map.linkMarkers([n[elem].coordinate["o-module-mapping:lat"],n[elem].coordinate["o-module-mapping:lng"]],[n[el["value_resource_id"]].coordinate["o-module-mapping:lat"],n[el["value_resource_id"]].coordinate["o-module-mapping:lng"]],false); 
                                            if(el["value_resource_id"] == this.$props.percorso){
                                                this.nextItems.push(n[elem])
                                            }
                                            else if(elem == this.$props.percorso){
                                                this.nextItems.push(n[el["value_resource_id"]])
                                            }
                                        }
                                    }
                                );
                            }
                            if(n[elem].item.hasOwnProperty("tri:linea_tratteggiata")){
                                n[elem].item["tri:linea_tratteggiata"].forEach(
                                    el => {
                                        if(n[el["value_resource_id"]] && !n[el["value_resource_id"]].item.hasOwnProperty("tri:Non_vertice")){
                                            this.map.linkMarkers([n[elem].coordinate["o-module-mapping:lat"],n[elem].coordinate["o-module-mapping:lng"]],[n[el["value_resource_id"]].coordinate["o-module-mapping:lat"],n[el["value_resource_id"]].coordinate["o-module-mapping:lng"]],true); 
                                            if(elem == this.$props.percorso){
                                                this.nextItems.push(n[el["value_resource_id"]])
                                            }
                                            else if(el["value_resource_id"] == this.$props.percorso){
                                                this.nextItems.push(n[elem])
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            },
            currentElem(n,o){
                if(n){
                    var self = this
                    
                    self.updateIn(n)
                    self.updateCorr(n)

                    if(this.isPerson || this.isObj){
                        var id = Common.safeString(n,["item","tri:item_in_geodato",0,"value_resource_id"]);
                        if(!id){
                            id = Common.safeString(n,["item","tri:item_coll_a_item",0,"value_resource_id"]);
                        }
                        Common.getElemById(this,id, res => {
                            self.parentResource = res.body
                        })
                    }
                    this.updateImg()
                }
            },

            complete_descr_maglia(n,o){
                if(this.infoMaglia) {
                  const maglia_subj = this.s(this.$store.getters.getMagliaSelezionata, ["tri:Titolo_" + this.$store.getters.getLanguage, 0, "@value"])
                  Common.addLlmContextMessage(this, 'maglia', maglia_subj, n)
                }
            },

            complete_descr(n,o){
              if(!this.infoMaglia){
                const maglia_subj =this.s(this.$store.getters.getMagliaSelezionata,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])
                Common.addLlmContextMessage(this, 'percorso', [maglia_subj, this.s(this.currentElem,["item","tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])], n)
                this.updateQuestions()
              }
            },

            $route(n,o) {
                this.infoMaglia = n.path.includes("maglia")
                if(this.$refs.collapseMaglia)
                    this.$refs.collapseMaglia.deactivateAll()
                if(this.$refs.collapsePivot)
                    this.$refs.collapsePivot.deactivateAll()
            }
        },

        computed : {
            baseline: function(){
              return !sessionStorage.getItem("sys_userstudy") || sessionStorage.getItem("sys_userstudy") === 'NoGeneratedNoFree'
            },
            generated: function(){
              return sessionStorage.getItem("sys_userstudy") && sessionStorage.getItem("sys_userstudy").includes("YesGenerated")
            },
            free: function(){
              return sessionStorage.getItem("sys_userstudy") && sessionStorage.getItem("sys_userstudy").includes("YesFree")
            },
            map: function(){
                return this.$refs.header.$refs.mapPercorso
            },
            isPlace: function(){
                return this.maglia.hasOwnProperty(this.$props.percorso)
            },
            isPerson: function(){
                return this.personeMaglia.hasOwnProperty(this.$props.percorso)
            },
            isObj: function(){
                return this.oggettiMaglia.hasOwnProperty(this.$props.percorso)
            },
            currentElem: function(){
                if(this.isPerson)
                    return this.personeMaglia[this.$props.percorso]
                else if(this.isObj)
                    return this.oggettiMaglia[this.$props.percorso]
                else
                    return this.maglia[this.$props.percorso]
            },
            title: function(){
                return this.$store.getters.getMagliaSelezionata === null ? "Dettagli percorso" : this.$store.getters.getMagliaSelezionata["tri:Titolo_"+this.$store.getters.getLanguage][0]["@value"]
            },
            favTitle: function(){
                return this.title +(this.infoMaglia ? "" : " - " + this.currentElem["item"]["tri:Titolo_"+this.$store.getters.getLanguage][0]["@value"])
            },

            // The following 4 methods get a list of items excluding currentElem
            // otherLocations also excludes elements which are hidden from the map
            otherLocations: function(){
                var self = this
                return this.objFilter(this.maglia,el => el.item['o:id'] !== self.$props.percorso && 
                !self.nextItems.some(i => i && el.item['o:id'] === i.item['o:id']) && 
                !el.item.hasOwnProperty("tri:Non_vertice"))
            },

            // Description of the current item (collapsed if necessary)
            complete_descr: function(){
                var complete;
                if(this.s(this.currentElem,["item","dcterms:description",0,"@value"]) !== "")
                    complete = this.s(this.currentElem['item'],["dcterms:description",0,"@value"])
                else
                    complete = this.s(this.currentElem,["pivot","dcterms:description",0,"@value"])
                return complete
            },
            descr: function(){
                var complete = Common.processMarkdown(this.complete_descr)
                if(complete.length <= 350 || this.expandedDescription)
                    return [hyphenateSync(complete),false]
                else
                    return [hyphenateSync(complete.substr(0,347)) + '...',true]
            },

            // Description of the current maglia (collapsed if necessary)
            complete_descr_maglia: function(){
                if(this.$store.getters.getMagliaSelezionata["dcterms:title"][0]["@value"] === "MISURARE3")
                  return this.s(this.$store.getters.getMagliaSelezionata,["dcterms:description",0,"@value"]).substr(0,643)
                else return this.s(this.$store.getters.getMagliaSelezionata,["dcterms:description",0,"@value"])
            },
            descr_maglia: function(){
                var complete = Common.processMarkdown(this.complete_descr_maglia)
                if(complete.length <= 350 || this.expandedDescription)
                    return [hyphenateSync(complete),false]
                else
                    return [hyphenateSync(complete.substr(0,347)) + '...',true]
            },

            // Gets all maglie corresponding to the current pivot
            maglieCorrelatePivot: function(){
                if(!this.$store.getters.getMagliaSelezionata)
                    return []
                var self = this
                
                return [...new Set(this.$store.getters.getMaglieCorrelatePivot.filter(el => {
                    //var isDuplicate = this.$store.getters.getMaglieCorrelate.some(elem => el['o:id'] === elem['o:id'])
                    return el['o:id'] !== self.$store.getters.getMagliaSelezionata['o:id'] && (self.s(el,['dcterms:accessRights',0,'@value']) === "YES" || localStorage.getItem("admin") === self.$store.getters.getPw)
                }))]
            },
        }
    }
</script>
<style scoped>
::placeholder {
  color: #282828;
  opacity: 0.9; /* Firefox */
}
#customQuestionButton:disabled{
  color:dimgrey;
}
@media only screen and (min-width: 768px){
  #customQuestion {
    font-size: 1.3em !important;
  }
}
@media only screen and (max-width: 768px){
  #customQuestion {
    font-size: 14px !important;
  }
}
h3{
    text-align:center;
    margin-top:3%;
}
.blockquote{
  color:black;
  margin-top:0.5%;
}
.blck{
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom:0;
}
p, span{
    text-align: justify;
    text-justify: inter-word;
}
.titlePercorso{
    margin-top:0px;
}
#stickyTitle{
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.flash {
  animation: flash 1000ms 2;
  border-radius: 3px;
  display: inline-block;
}

@keyframes flash {
  50% {
    background: rgb(255, 206, 55);
  }
}
</style>
<template>
    <span></span>
</template>
<script>
import {Button} from '@/components'
export default {
    components: {
        [Button.name] : Button
    },
    computed: {
        isSaved: function(){return this.$store.getters.getFavourites.find(el => el.link === this.$parent.$route.path) !== undefined}
    },
    methods:{
        saveBookmark(){
            this.$store.commit("addFavourite",{'link':this.$parent.$route.path,'title':this.$parent.$parent.favTitle,'img':this.$parent.$parent.imgUrl})
        },

        removeBookmark(){
            this.$store.commit("removeFavourite",this.$parent.$route.path)
        }
    }
}
</script>
<template>
  <div id="cont">
    <div v-for="(item, index) in $props.questions" :key="index" class="question-box">
      <Question :question="item" :expandable="$props.expandable" :fallback-for-not-expandable="$props.fallbackForNotExpandable" />
    </div>
  </div>
</template>

<script>
import Question from "@/pages/components/Question";
export default {
  components: {Question},
  props: {
    questions: {
      type: Array,
      required: true
    },
    expandable: {
      type: Boolean,
      required: true
    },
    handler:{
      type: Function,
      default: (el) => {}
    },
    fallbackForNotExpandable: {
      type: Function,
      default: (el) => {}
    }
  },
};
</script>

<style scoped>
#cont {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
  text-align: left;
}

.question-box{
  padding: 1em;
  border-bottom: 1px solid #ccc;
}
</style>